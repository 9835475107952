/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import { createSlice, current, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';

import prepareContentType from '../../utils/prepare-content-type';
import API from '../../api';
import { APIError, resetRequestState } from '../app';
import { setCurrentReportId } from '../current-report';
import getFormattedDate from '../../utils/get-formatted-date';
import testConditional from '../../utils/test-conditional';

import {
    SECTION_PROGRESS,
} from '../current-report/constants';

export const REQUEST_STATUS = {
    ACTIVE: 'ACTIVE',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
};

export const REPORT_STATUS = {
    DRAFT: 'DRAFT',
    SUBMITTED: 'SUBMITTED',
    REOPENED: 'REOPENED',
};

export const REPORT_OUTCOME = {
    POSITIVE: 'POSITIVE',
    NEGATIVE: 'NEGATIVE',
    INCONCLUSIVE: 'INCONCLUSIVE',
    PENDING: 'PENDING',
};

export const getCurrentVersion = (report) => report.versions.find((version) => version.isActiveVersion);

export const getFlaggedVersion = (report) => {
    if (!report?.versions) return null;

    const currentVersion = getCurrentVersion(report);
    if (currentVersion.versionNumber === 1 || report.status !== REPORT_STATUS.REOPENED) {
        return currentVersion;
    }
    return report.versions.find((version) => version.versionNumber === currentVersion.versionNumber - 1);
};

const determinePercentComplete = (sectionsProgress) => {
    const totalSections = Object.keys(sectionsProgress).length;
    const percentComplete = Object.values(sectionsProgress).reduce((accumulator, status) => {
        // give 100% credit for each section completed
        if (status === SECTION_PROGRESS.COMPLETED) return accumulator + 100 / totalSections;
        // give 50% credit if a section has at least been started
        if (status === SECTION_PROGRESS.IN_PROGRESS) return accumulator + 100 / (totalSections * 2);

        // otherwise section has not been started so nothing to add
        return accumulator;
    }, 0);

    if (percentComplete > 100) return 100;

    return Math.round(percentComplete);
};

/*
 - This is bad code. It's too complex and hard to follow. It's also not very efficient.
 - this is nessisary though because the FE saves answers indepdent of sections so we cannot just identify a section as complete or draft
 - meaning, when we look at a reports answers, we just have a huge list of ID's that aren't directly tied to a section
 - we then have to loop over all questions and determine if they are required and answered
*/
export const determineSectionsProgress = (reportAnswers, flaggedAnswers, testSections) => {
    const sectionsProgress = {};
    // loop over testSections and determine the progress of each section
    // progress will be determined based on the number of required questions answered
    testSections.forEach((section) => {
        const { id, questions } = section;

        // todo note: if a conditional question is answered and saved, but then we change the condition and the answered question is no longer visible, the answer should be cleared, but I'm not sure that is handled yet
        // if we don't clear it, it will throw off the answeredQuestions count

        // no conditionals on the top level questions, so just filter for required questions
        let requiredQuestions = questions.filter((question) => question.required);
        // L1 question loop
        requiredQuestions.forEach((question) => {
            // if there are nested questions, filter for required questions
            if (question.questions) {
                let childQuestions = [];

                const L1WatchValue = parseInt(reportAnswers[question.id], 10);
                const L1IsRepeatingGroup = question.type === 'repeatingGroup';

                // if repeating group, loop over the number of times the group should repeat
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < (L1IsRepeatingGroup ? L1WatchValue : 1); i++) {
                    // L2 question loop for children
                    // eslint-disable-next-line no-loop-func
                    question.questions.forEach((childQuestion) => {
                        if (childQuestion.required) {
                            // add the iterative number to the question id
                            const amendedQuestion = { ...childQuestion };
                            if (L1IsRepeatingGroup) {
                                amendedQuestion.id = `${childQuestion.id}-${i}-${question.id}`;
                            }
                            childQuestions.push(amendedQuestion);
                        }
                    });
                }
                // determine if there the renderLogic has been met for the child questions
                // it will always pass for repeatingGroup questions
                childQuestions = childQuestions.filter((childQuestion) => testConditional(childQuestion.renderLogic, reportAnswers[question.id]));

                // add the child questions to the requiredQuestions array
                requiredQuestions = requiredQuestions.concat(childQuestions);

                // L3 question loop
                let grandChildQuestions = [];
                // to be in alignment with /reports/components/section, id amdendments of L3 questions are needed for all questions
                childQuestions.forEach((childQuestion) => {
                    if (childQuestion.questions) {
                        const parentL2WatchValue = parseInt(reportAnswers[childQuestion.id], 10);
                        const parentL2IsRepeatingGroup = childQuestion.type === 'repeatingGroup';

                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < (parentL2IsRepeatingGroup ? parentL2WatchValue : 1); i++) {
                            // eslint-disable-next-line no-loop-func
                            childQuestion.questions.forEach((grandChildQuestion) => {
                                if (grandChildQuestion.required) {
                                    const amendedQuestion = { ...grandChildQuestion };
                                    amendedQuestion.id = `${grandChildQuestion.id}-${i}-${childQuestion.id}`;
                                    grandChildQuestions.push(amendedQuestion);
                                }
                            });
                        }

                        // determine if there the renderLogic has been met for the grandchild questions
                        // it will always pass for repeatingGroup questions
                        grandChildQuestions = grandChildQuestions.filter((grandChildQuestion) => testConditional(grandChildQuestion.renderLogic, reportAnswers[childQuestion.id]));

                            // add the grandchild questions to the requiredQuestions array
                        requiredQuestions = requiredQuestions.concat(grandChildQuestions);
                    }
                });
            }
        });
        // remove any question that is of type 'instructions'
        // remove any question that is of type 'file' - these can be marked as 'required', but are not
        requiredQuestions = requiredQuestions.filter((question) => (question.type !== 'instructions' && question.type !== 'file'));

        // determine the number of required questions that have been answered
        const answeredQuestions = requiredQuestions.filter((question) => (reportAnswers[question.id] !== null && reportAnswers[question.id] !== undefined && reportAnswers[question.id] !== ''));

        // determine the number of required questions that have been flagged
        let flaggedQuestions = [];
        if (flaggedAnswers) {
            flaggedQuestions = requiredQuestions.filter((question) => !!flaggedAnswers[question.id]);
            // const flaggedQuestions = requiredQuestions.filter((question) => flaggedAnswers?.includes(question.id));
        }

        // determine the progress of the section based on the number of required questions answered
        if (flaggedQuestions.length > 0) {
            sectionsProgress[id] = SECTION_PROGRESS.FLAGGED;
        } else if (answeredQuestions.length === 0 && requiredQuestions.length > 0) { // if a section has no required questions, it is considered complete. this potentially could be a bad UX but there isn't a clear alternative
            sectionsProgress[id] = SECTION_PROGRESS.NOT_STARTED;
        } else if (answeredQuestions.length < requiredQuestions.length) {
            sectionsProgress[id] = SECTION_PROGRESS.IN_PROGRESS;
        } else {
            sectionsProgress[id] = SECTION_PROGRESS.COMPLETED;
        }
    });

    return sectionsProgress;
};

const parseFetchedAnswers = (report) => {
    const sortedVersions = orderBy(report.versions, ['versionNumber'], ['asc']);
    const parsedAnswers = {};

    const allAnwers = sortedVersions.reduce((acc, version) => [...acc, ...version.reportAnswers], []);

    // transform the fetched answers into an object keyed by question id
    allAnwers.forEach((reportAnswer) => {
        const { questionId, answer } = reportAnswer;
        parsedAnswers[questionId] = answer;
    });

    return parsedAnswers;
};

const parseFetchedReports = (reports) => {
    const parsedReports = {};

    // transform the fetched reports data into the shape and formats we need
    // NOTE: this will break any sorting that was done on the server because reports are now listed by id
    reports.forEach((report) => {
        const { id, ...reportData } = report;
        if (!report.test?.sections) return null;

        const reportAnswers = parseFetchedAnswers(report);
        const flaggedAnswers = getFlaggedVersion(report)?.flaggedAnswers;
        const sectionsProgress = determineSectionsProgress(reportAnswers, flaggedAnswers, report.test.sections);
        const percentComplete = determinePercentComplete(sectionsProgress);

        parsedReports[id] = {
            id,
            ...reportData,
            reportAnswers,
            sectionsProgress,
            percentComplete,
        };
    });

    return parsedReports;
};

const prepareReport = (sections, answers) => {
    const preparedReport = [];

    // build array of sections with sectionAnswers array of objects
    // keyed by question with the answer as the value
    sections.forEach((section) => {
        const sectionAnswers = [];

        Object.values(section.questions).forEach((question) => {
            let answer = answers[question.id];
            const { options } = question;
            const { type } = question;

            if (answer) {
                // format datetime values as appropriate (date or time respectively)
                if (type === 'date') {
                    answer = getFormattedDate(answer);
                // this is hold over code, but it's taking in all string values, which is 'shortText' and 'longText', 'radioButtons', etc.
                } else if (!Number.isNaN(answer)) {
                    answer = `${answer}`;
                } else if (type === 'checkBoxes') {
                    if (typeof answer === 'string') {
                        answer = answer?.split(',');
                    }

                    if (options.length > 1) {
                        // replace checkbox id's with their names
                        answer = answer.map((value) => options.find((option) => option.id === value).name);
                        answer = answer.join(', ');
                    } else {
                        // when the checkbox is a single checkbox the answer should instead be Yes/No
                        answer = 'Yes';
                    }
                }
            } else if (type === 'checkBoxes') {
                if (options.length > 1) {
                    answer = 'None';
                } else {
                    answer = 'No';
                }
            } else {
                // replace null and undefined values with 'N/A'
                // (these should only be conditional questions where the condition was not met)
                answer = 'N/A';
            }

            // NOTE: question as a param/value is needed for the API
            // it's unclear if the current value being provided is correct as it's structured different than the old app
            sectionAnswers.push({ question: question.text, answer, questionId: question.id });
        });

        preparedReport.push({
            section: section.id,
            sectionAnswers,
        });
    });

    return preparedReport;
};

const downloadReport = createAsyncThunk('reports/downloadReport', async ({ reportId, onSuccess = noop, onError = noop }) => {
        try {
            const responseUrl = await API.downloadReport(reportId);
            onSuccess(responseUrl);
        } catch (error) {
            onError(error);
        }
    },
);

const getReports = createAsyncThunk('reports/getReports', async ({ params = {} }, thunkAPI) => {
    const defaultParams = {
        pageNumber: 0,
        pageSize: 10,
        sortBy: 'testDate',
        sortOrder: 'asc',
    };

    const populatedPayload = Object.assign(defaultParams, params);

    try {
        const response = await API.fetchReports(populatedPayload);
        return response;
    } catch (error) {
        thunkAPI.dispatch(APIError({ text: 'Error retrieving reports' }));
    }
});

const deleteReport = createAsyncThunk('reports/deleteReport', async ({ id, onSuccess = noop }, thunkAPI) => {
    try {
        await API.deleteReport(id);
        onSuccess();
    } catch (error) {
        thunkAPI.dispatch(APIError({ text: 'Error deleting report' }));
        onSuccess();
    }
});

const prepareReportAnswers = (questions, answers) => {
    const preparedAnswers = answers;

    // loop over the answers and ensure that any answer value that isn't null is set to a string
    Object.keys(preparedAnswers).forEach((key) => {
        if (preparedAnswers[key] !== null) {
            if (Array.isArray(preparedAnswers[key])) {
                preparedAnswers[key] = preparedAnswers[key].join();
            }
            if (typeof preparedAnswers[key] === 'number') {
                preparedAnswers[key] = preparedAnswers[key].toString();
            }
            // the API doesn't want empty strings so replace with null
            if (preparedAnswers[key] === '') {
                preparedAnswers[key] = null;
            }
        }
    });

    // This removed answers that are no longer visible based on the renderLogic
    // This is needed specifically so we can build the report with the correct answers
    // NOTE: with this code, it's still possible to have repeating group answers that are no longer visible
    // this is possible if the repeating group value is set to a value greater than 0, and then reduced to 0.
    // however, when building the report the API loops over this value, so unneeded answers are trimmed at that time

    // loop over the questions, and remove any answers from the preparedAnswers object that is associated with a question that is no longer visible
    // top level questions are always visible
    // child questions are only visible if the parent question has a value that meets the renderLogic
    // grandchild questions are only visible if the parent question has a value that meets the renderLogic and it has a value that meets the renderLogic of it's parent
    questions.forEach((question) => {
        if (question.questions) {
            const L1WatchValue = parseInt(preparedAnswers[question.id], 10);
            const L1IsRepeatingGroup = question.type === 'repeatingGroup';

            // if repeating group, loop over the number of times the group should repeat
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < (L1IsRepeatingGroup ? L1WatchValue : 1); i++) {
                // L2 question loop for children
                // eslint-disable-next-line no-loop-func
                question.questions.forEach((childQuestion) => {
                    // add the iterative number to the question id
                    const amendedQuestion = { ...childQuestion };
                    if (L1IsRepeatingGroup) {
                        amendedQuestion.id = `${childQuestion.id}-${i}-${question.id}`;
                    }
                    // test the rederLogic for the L2 child question against the L1 question value
                    if (!testConditional(amendedQuestion.renderLogic, preparedAnswers[question.id])) {
                        // if the renderLogic fails, remove the answer from the preparedAnswers object
                        delete preparedAnswers[amendedQuestion.id];
                        // if the L2 question has children, loop over them, and remove any answers from the preparedAnswers object that is associated with a question that is no longer visible
                        if (amendedQuestion.questions) {
                            const parentL2WatchValue = parseInt(preparedAnswers[amendedQuestion.id], 10);
                            const parentL2IsRepeatingGroup = amendedQuestion.type === 'repeatingGroup';

                            // eslint-disable-next-line no-plusplus
                            for (let i2 = 0; i2 < (parentL2IsRepeatingGroup ? parentL2WatchValue : 1); i2++) {
                                // eslint-disable-next-line no-loop-func
                                amendedQuestion.questions.forEach((grandChildQuestion) => {
                                    // add the iterative number to the question id
                                    const amendedGrandChildQuestion = { ...grandChildQuestion };
                                    amendedGrandChildQuestion.id = `${grandChildQuestion.id}-${i2}-${amendedQuestion.id}`;
                                    // no test on L3 questions, just remove the answer
                                    delete preparedAnswers[amendedGrandChildQuestion.id];
                                });
                            }
                        }
                    } else if (amendedQuestion.questions) {
                        // if the renderLogic passes, and the L2 question has children
                        // we need to loop over them and check the renderLogic against the L2 question value
                        // if the renderLogic fails, remove the answer from the preparedAnswers object
                        const parentL2WatchValue = parseInt(preparedAnswers[amendedQuestion.id], 10);
                        const parentL2IsRepeatingGroup = amendedQuestion.type === 'repeatingGroup';

                        // eslint-disable-next-line no-plusplus
                        for (let i2 = 0; i2 < (parentL2IsRepeatingGroup ? parentL2WatchValue : 1); i2++) {
                            // eslint-disable-next-line no-loop-func
                            amendedQuestion.questions.forEach((grandChildQuestion) => {
                                // add the iterative number to the question id
                                const amendedGrandChildQuestion = { ...grandChildQuestion };
                                amendedGrandChildQuestion.id = `${grandChildQuestion.id}-${i2}-${amendedQuestion.id}`;
                                // test the rederLogic for the L3 grandchild question against the L2 question value
                                if (!testConditional(amendedGrandChildQuestion.renderLogic, preparedAnswers[amendedQuestion.id])) {
                                    // if the renderLogic fails, remove the answer from the preparedAnswers object
                                    delete preparedAnswers[amendedGrandChildQuestion.id];
                                }
                            });
                        }
                    }
                });
            }
        }
    });

    return preparedAnswers;
};

const submitReopenRequest = createAsyncThunk('reports/submitReopenRequest', async ({ reportId, payload, onSuccess, onError }) => {
    try {
        const newVersion = await API.submitReopenRequest(reportId, payload);
        onSuccess(newVersion);
    } catch (error) {
        onError(error);
    }
});

const saveReportSectionData = createAsyncThunk(
    'reports/saveReportSectionData',
    async ({ reportId, questions, answers, onComplete, onError }, thunkAPI) => {
        try {
            const payload = {
                answers: prepareReportAnswers(questions, answers),
            };
            await API.submitReportAnswers(reportId, payload);
            onComplete();
        } catch (error) {
            // we handle different types of errors at the point of saving
            if (onError) onError(error);
            else { // this shouldn't be needed, but incase onError isn't available ensure feedback
                thunkAPI.dispatch(APIError({ text: 'Error saving report answers' }));
            }
        }
    },
);

const uploadOutcome = createAsyncThunk(
    'reports/outloadOutcome',
    async ({ reportId, outcomeData, onSuccess = noop, onError = noop }, thunkApi) => {
        const { outcome, outcomeFile } = outcomeData;

        try {
            const payload = {
                outcome,
            };

            if (outcomeFile) {
                payload.outcomeFile = outcomeFile;
            }

            const [formData, config] = prepareContentType('form-data', payload);
            const response = await API.uploadReportOutcome(reportId, formData, config);

            onSuccess();
            return response;
        } catch (error) {
            thunkApi.dispatch(APIError({ text: 'Error uploading attachments' }));
            onError(error);
        }
    },
);

const uploadReportAttachments = createAsyncThunk(
    'reports/updateAttachments',
    async ({ reportId, attachments, questions, onError }, thunkApi) => {
        try {
            const reportAnswers = thunkApi.getState().reports.data[reportId]?.reportAnswers;
            // Find all of the questions that are file upload questions for the current section
            const fileQuestionIds = questions.filter((q) => q.type === 'file').map((q) => q.id);
            // Find attachments that have already been uploaded for the current section
            // based on filter the answers by the file upload question ids
            const previousAttachments = Object.entries(reportAnswers)
                .filter((answer) => answer[0].match(new RegExp(fileQuestionIds.join('|'))))
                .filter((answer) => answer[1] !== null)
                .map((answer) => answer[1]);

            let newAttachments = attachments;
            let removedAttachments = [];

            // if attachments have been uploaded previously determine which ones
            // are being added and which ones have been removed
            if (previousAttachments && previousAttachments.length) {
                newAttachments = attachments.filter(
                    (attachment) => !previousAttachments.some((prevAttachment) => prevAttachment === attachment.name),
                );
                removedAttachments = previousAttachments.filter(
                    (prevAttachment) => !attachments.some((attachment) => attachment.name === prevAttachment),
                );
            }

            // upload any new Attachments
            if (newAttachments.length) {
                const uploadPayload = {
                    attachment: newAttachments,
                };
                const [formData, config] = prepareContentType('form-data', uploadPayload);

                await API.uploadReportAttachments(reportId, formData, config);
            }

            // delete any removed Attachments
            if (removedAttachments.length) {
                const deletePayload = {
                    attachments: removedAttachments,
                };
                await API.deleteReportAttachments(reportId, { data: deletePayload });
            }
        } catch (error) {
            if (onError) onError(error);
            else {
                thunkApi.dispatch(APIError({ text: 'Error uploading attachments' }));
            }
        }
    },
);

const getReportsForTester = createAsyncThunk('reports/getReportsForTester', async (userId) => {
    const queryParams = {
        testers: [userId],
    };

    const response = await API.fetchReports(queryParams);
    return response;
});

const createReport = createAsyncThunk(
    'reports/createNewReport',
    async ({ tempId, reportData, onSuccess = noop, onError = noop }, thunkApi) => {
        try {
            const payload = {
                siteId: reportData.testSite.id,
                testNumber: reportData.testNumber,
                testCoordinator: reportData.testCoordinator,
                testDate: reportData.testDate,
            };

            const response = await API.createReport(payload);

            onSuccess();

            // set up the current report with the new report's details
            thunkApi.dispatch(setCurrentReportId(response.id));

            return response;
        } catch (error) {
            // set up the current report with the new report's details
            // use the temp id since we weren't able to POST the new report successfully
            thunkApi.dispatch(setCurrentReportId(tempId));

            onError();
            throw error;
        }
    },
);

const submitReport = createAsyncThunk('reports/submitReport', async ({ reportId, onSuccess, onError }, thunkApi) => {
    try {
        const state = thunkApi.getState();
        const { sections } = state.reports.data[reportId].test;

        const { reportAnswers } = state.reports.data[reportId];
        const reportData = prepareReport(sections, reportAnswers);

        await API.submitReport(reportId, reportData);

        onSuccess();
    } catch (error) {
        if (onError) onError(error);
    }
});

const getCurrentTest = createAsyncThunk('reports/getCurrentTest', async (_, thunkApi) => {
    try {
        const response = await API.getCurrentTest();

        return response;
    } catch (error) {
        thunkApi.dispatch(APIError({ text: 'Error getting report questions.' }));
    }
});

const patchReportVersion = createAsyncThunk('reports/patchReportVersion', async ({ versionId, payload, onSuccess, onError }) => {
    try {
        const { comment, flaggedAnswers } = payload;
        await API.patchReportVersion(versionId, comment, flaggedAnswers);
        onSuccess();
    } catch (error) {
        onError(error);
    }
});

const initialState = {
    data: {},
    currentRequestId: null,
    errorMsg: null,
    loading: {},
    total: 0,
    attachmentUploadStatus: {
        inProgress: false,
    },
    test: null,
};

const initialReportState = {
    status: REPORT_STATUS.DRAFT,
    testNumber: null,
    testCoordinator: null,
    testDate: null,
    outcome: null,
    outcomeFile: null,
    submittedDate: null,
    percentComplete: 0,
    testSite: {},
    tester: {},
    sectionsProgress: {}, // INITIAL_SECTION_PROGRESS,
    reportAnswers: {},
    isSyncNeeded: true,
    requestStatus: null,
    errorMsg: null,
};

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: (create) => ({
        createReportVersionSuccess: create.preparedReducer(
            (reportId, newVersion) => ({ payload: { reportId, newVersion } }),
            (state, action) => {
                state.data = {
                    ...state.data,
                    [action.payload.reportId]: {
                        ...state.data[action.payload.reportId],
                        versions: [
                            ...state.data[action.payload.reportId].versions,
                            action.payload.newVersion,
                        ],
                    },
                };
            },
        ),
        // this gets manually updated via the UI
        // also accessed as the success handler for "patchReportVersion" thunk
        patchReportVersionSuccess: create.preparedReducer(
            (reportId, versionId, updates) => ({ payload: { reportId, versionId, updates } }),
            (state, action) => {
                const currentVersions = current(state).data[action.payload.reportId].versions;
                state.data = {
                    ...state.data,
                    [action.payload.reportId]: {
                        ...state.data[action.payload.reportId],
                        requestStatus: REQUEST_STATUS.SUCCESS,
                        versions: currentVersions.map((version) => {
                            if (version.id === action.payload.versionId) {
                                return {
                                    ...version,
                                    ...action.payload.updates,
                                };
                            }
                            return version;
                        }),
                    },
                };
            },
        ),
        patchReportSuccess: create.preparedReducer(
            (reportId, updates) => ({ payload: { reportId, updates } }),
            (state, action) => {
                state.data = {
                    ...state.data,
                    [action.payload.reportId]: {
                        ...state.data[action.payload.reportId],
                        // this may be overwritten in the "updates" object
                        // however, this call is made from a few places, and not all include the status
                        // in at one places it's used to update the status to REOPENED
                        requestStatus: REQUEST_STATUS.SUCCESS,
                        ...action.payload.updates,
                    },
                };
            },
        ),
        // this gets manually updated via the UI
        // also accessed as the error handler for "patchReportVersion" thunk
        patchReportError: create.preparedReducer(
            (reportId, error, requestStatus) => ({ payload: { reportId, error, requestStatus } }),
            (state, action) => {
                state.data = {
                    ...state.data,
                    [action.payload.reportId]: {
                        ...state.data[action.payload.reportId],
                        requestStatus: action.payload.requestStatus,
                        errorMsg: action.payload.error,
                    },
                };
            },
        ),
        updateSectionProgress: create.preparedReducer(
            // consolidates setSectionToInProgress/setSectionToCompleted/setSectionToFlagged from OG app
            (reportId, sectionId, progress) => ({ payload: { reportId, sectionId, progress } }),
            (state, action) => {
                const updatedSectionsProgress = {
                    ...state.data[action.payload.reportId].sectionsProgress,
                    [action.payload.sectionId]: action.payload.progress,
                };

                state.data = {
                    ...state.data,
                    [action.payload.reportId]: {
                        ...state.data[action.payload.reportId],
                        percentComplete: determinePercentComplete(updatedSectionsProgress),
                        sectionsProgress: updatedSectionsProgress,
                    },
                };
            },
        ),
    }),
    extraReducers: (builder) => {
        builder
            .addCase(resetRequestState, (state) => {
                state.errorMsg = initialState.errorMsg;
                state.loading = initialState.loading;
                state.currentRequestId = initialState.currentRequestId;
                state.attachmentUploadStatus = {
                    inProgress: false,
                };
            })
            .addCase(getCurrentTest.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;
                    state.test = action.payload;
                }
            })
            // NOTE: we are using values from the UI to save to the application state, not a return values from the API
            .addCase(saveReportSectionData.pending, (state, action) => {
                const {
                    requestId,
                    arg: { reportId, answers },
                } = action.meta;
                if (state.loading[requestId] === 'idle' || !state.loading[requestId]) {
                    state.loading[requestId] = 'pending';
                    state.currentRequestId = requestId;

                    state.data = {
                        ...state.data,
                        [reportId]: {
                            ...state.data[reportId],
                            reportAnswers: {
                                ...state.data[reportId].reportAnswers,
                                ...answers,
                            },
                        },
                    };
                }
            })
            .addCase(saveReportSectionData.fulfilled, (state, action) => {
                const {
                    requestId,
                    arg: { reportId },
                } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;

                    state.data = {
                        ...state.data,
                        [reportId]: {
                            ...state.data[reportId],
                            isSyncNeeded: false,
                            requestStatus: REQUEST_STATUS.SUCCESS,
                        },
                    };
                }
            })
            .addCase(saveReportSectionData.rejected, (state, action) => {
                const {
                    requestId,
                    arg: { reportId },
                } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.error = action.error;
                    state.currentRequestId = undefined;

                    if (action.error.response && action.error.response.status === 401) {
                        state.data = {
                            ...state.data,
                            [reportId]: {
                                ...state.data[reportId],
                                isSyncNeeded: true,
                                requestStatus: REQUEST_STATUS.SESSION_EXPIRED,
                                errorMsg:
                                    'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.',
                            },
                        };
                    }
                }
            })
            .addCase(deleteReport.fulfilled, (state, action) => {
                const {
                    requestId,
                    arg: { id },
                } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    const updatedData = { ...state.data };
                    delete updatedData[id];
                    state.data = {
                        ...updatedData,
                    };
                    state.currentRequestId = undefined;
                }
            })
            .addCase(uploadOutcome.pending, (state, action) => {
                const {
                    arg: { reportId },
                } = action.meta;
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'idle' || !state.loading[requestId]) {
                    state.loading[requestId] = 'pending';
                    state.currentRequestId = action.meta.requestId;
                    state.data = {
                        ...state.data,
                        [reportId]: {
                            ...state.data[reportId],
                            requestStatus: REQUEST_STATUS.ACTIVE,
                        },
                    };
                }
            })
            .addCase(uploadOutcome.fulfilled, (state, action) => {
                const {
                    requestId,
                    arg: { reportId },
                } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;
                    state.data = {
                        ...state.data,
                        [reportId]: {
                            ...state.data[reportId],
                            ...action.payload,
                            requestStatus: REQUEST_STATUS.SUCCESS,
                        },
                    };
                }
            })
            .addCase(uploadOutcome.rejected, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;
                    state.data = {
                        ...state.data,
                        [action.payload.reportId]: {
                            ...state.data[action.payload.reportId],
                            requestStatus: action.payload.requestStatus,
                            errorMsg: action.payload.errorMsg || REPORT_STATUS.ERROR,
                        },
                    };
                }
            })
            .addCase(uploadReportAttachments.pending, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'idle' || !state.loading[requestId]) {
                    state.loading[requestId] = 'pending';
                    state.currentRequestId = requestId;
                    state.attachmentUploadStatus = {
                        inProgress: true,
                    };
                }
            })
            .addCase(uploadReportAttachments.fulfilled, (state, action) => {
                const {
                    requestId,
                    arg: { reportId, attachments },
                } = action.meta;

                if (state.loading[requestId] === 'pending' && state.currentRequestId === requestId) {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;
                    state.attachmentUploadStatus = {
                        inProgress: false,
                    };

                    // Store only the names of the attachments,
                    // not the full File object
                    const formattedAttachments = attachments.map((a) => ({ name: a.name }));

                    state.data = {
                        ...state.data,
                        [reportId]: {
                            ...state.data[reportId],
                            attachments: formattedAttachments,
                        },
                    };
                }
            })
            .addCase(uploadReportAttachments.rejected, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.error = action.error;
                    state.currentRequestId = undefined;
                    state.attachmentUploadStatus = {
                        inProgress: false,
                    };
                }
            })
            .addCase(createReport.pending, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'idle' || !state.loading[requestId]) {
                    state.loading[requestId] = 'pending';
                    state.currentRequestId = requestId;

                    state.data = {
                        ...state.data,
                        [action.meta.arg.tempId]: {
                            // save with a tempId until we receive the new Id from the API
                            ...initialReportState,
                            ...action.meta.arg.reportData,
                            isSyncNeeded: true,
                            requestStatus: REQUEST_STATUS.ACTIVE,
                            versions: [{ versionNumber: 1, isActiveVersion: true }],
                            questions: state.latestQuestions,
                        },
                    };
                }
            })
            .addCase(createReport.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;

                    const newReport = {
                        ...state.data[action.meta.arg.tempId],
                    };

                    // now that the report has been synced remove the tempId
                    delete state.data[action.meta.arg.tempId];

                    state.data = {
                        ...state.data,
                        // save with the new Id from the database
                        [action.payload.id]: {
                            id: action.payload.id,
                            ...newReport,
                            isSyncNeeded: false,
                            requestStatus: REQUEST_STATUS.SUCCESS,
                            ...action.payload.data,
                            test: state.test,
                        },
                    };
                }
            })
            .addCase(createReport.rejected, (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.error = action.error;
                    state.currentRequestId = undefined;
                    state.data = {
                        ...state.data,
                        [action.meta.arg.tempId]: {
                            ...state.data[action.meta.arg.tempId],
                            isSyncNeeded: true,
                            requestStatus: REQUEST_STATUS.ERROR,
                            errorMsg: action.payload.errorMsg,
                        },
                    };
                }
            })
            .addMatcher(isAnyOf(getReports.fulfilled, getReportsForTester.fulfilled), (state, action) => {
                const { requestId } = action.meta;
                if (state.loading[requestId] === 'pending') {
                    state.loading[requestId] = 'idle';
                    state.currentRequestId = undefined;
                    state.total = action.payload.total;
                    state.data = parseFetchedReports(action.payload.reports);
                    state.rawData = action.payload.reports;
                }
            })
            .addMatcher(
                isAnyOf(deleteReport.pending, getReports.pending, getReportsForTester.pending, getCurrentTest.pending),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading[requestId] === 'idle' || !state.loading[requestId]) {
                        state.loading[requestId] = 'pending';
                        state.currentRequestId = action.meta.requestId;
                    }
                },
            )
            .addMatcher(
                isAnyOf(
                    deleteReport.rejected,
                    getReports.rejected,
                    getReportsForTester.rejected,
                    getCurrentTest.rejected,
                ),
                (state, action) => {
                    const { requestId } = action.meta;
                    if (state.loading[requestId] === 'pending') {
                        state.loading[requestId] = 'idle';
                        state.error = action.error;
                        state.currentRequestId = undefined;
                    }
                },
            );
    },
});

const { reducer, actions } = reportsSlice;

export {
    downloadReport,
    getReports,
    deleteReport,
    uploadOutcome,
    getReportsForTester,
    createReport,
    submitReport,
    saveReportSectionData,
    uploadReportAttachments,
    getCurrentTest,
    patchReportVersion,
    submitReopenRequest,
};

export const { patchReportVersionSuccess, patchReportError, patchReportSuccess, updateSectionProgress, createReportVersionSuccess } = actions;

export default reducer;
