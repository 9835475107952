import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { withStyles } from 'tss-react/mui';
import isEqual from 'lodash/isEqual';
import Grid from '@mui/material/Grid';

import TablePaginationActions from '../../../components/table-pagination-actions';
import { getTesters } from '../../../redux/testers';
import { getTestersRawData, getTotalTesters } from '../../../redux/testers/selectors';

import TesterFilter from '../components/tester-filter';
import Pill from '../components/pill';
import TesterActionMenu from '../components/tester-action-menu';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import Styles from '../styles';
import DeleteTesterModal from './delete-tester-modal';
import { USER_ROLES } from '../../../utils/constants';
import { clearUserSuccessMessage } from '../../../redux/user';
import { getRole } from '../../../redux/auth/selectors';
import NotificationSnackbar from '../../../components/notification-snackbar';
import { getUserSuccessMessage } from '../../../redux/user/selectors';
import getStatusLabel from '../utils/get-status-label';

const MANAGE_TESTER_ROLES = [USER_ROLES.admin.value, USER_ROLES.testCoordinator.value, USER_ROLES.orgAdmin.value];

const ManageTesters = ({ classes, role }) => {
    const userRole = useSelector(getRole);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filterCriteria, setFilterCriteria] = useState({
        statuses: [],
        genders: [],
        ages: [],
        disability: '',
    });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [testerToDelete, setTesterToDelete] = useState({});
    const testerSuccessMessage = useSelector(getUserSuccessMessage);
    const [sortBy, setSortBy] = useState('fullName');
    const [sortOrder, setSortOrder] = useState('asc');
    const [fetchPayload, setFetchPaylod] = useState({
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy,
        sortOrder,
    });
    const testersTotal = useSelector(getTotalTesters);
    const testers = useSelector(getTestersRawData);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const payload = {
            pageNumber: page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 1) {
            payload.pageSize = rowsPerPage;
        }

        if (filterCriteria.statuses.length) {
            payload.status = filterCriteria.statuses;
        }
        if (filterCriteria.genders.length) {
            payload.genders = filterCriteria.genders;
        }
        if (filterCriteria.ages.length) {
            payload.ages = filterCriteria.ages;
        }
        if (filterCriteria.disability) {
            payload.hasDisability = filterCriteria.disability === 'Yes';
        }

        // we sort by age in the UI, but it's DOB in the database so we need to flip the sort order
        if (sortBy === 'dob') {
            payload.sortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        } else if (sortBy === 'disability') {
            payload.sortBy = 'hasDisability';
        }

        if (!isEqual(payload, fetchPayload)) {
            setFetchPaylod(payload);
        }
    }, [page, rowsPerPage, sortBy, sortOrder, filterCriteria, fetchPayload]);

    useEffect(() => {
        dispatch(getTesters(fetchPayload));
    }, [fetchPayload, dispatch]);

    const canManageTesters = MANAGE_TESTER_ROLES.includes(role);

    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleInviteNewTester = useCallback(() => {
        navigate('/dashboard/tester');
    }, [navigate]);

    const handleDelete = (tester) => {
        setOpenDeleteModal(true);
        setTesterToDelete(tester);
    };

    const handleChangeRowsPerPage = useCallback(
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage],
    );

    const handleSort = (sortProperty) => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    const handleUpdateFilters = useCallback(
        (values) => {
            if (!isEqual(values, filterCriteria)) {
                setPage(0);
                setFilterCriteria(values);
            }
        },
        [filterCriteria],
    );

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item container xs={12}>
                <Grid item xs={4}>
                    <Typography id="testers-title" variant="h1" component="h2" className={classes.pageTitle}>
                        Manage Testers
                    </Typography>
                    <Typography id="testers-count" variant="body1" className={classes.pageCount}>
                        {testersTotal} testers
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    {canManageTesters && (
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                className={classes.primaryButton}
                                classes={{
                                    label: classes.buttonLabel,
                                }}
                                TouchRippleProps={{
                                    classes: {
                                        childPulsate: classes.primaryButtonRippleChildPulsate,
                                        ripplePulsate: classes.buttonRipplePulsate,
                                    },
                                }}
                                style={{ minWidth: 'max-content' }}
                                onClick={handleInviteNewTester}
                            >
                                Invite New Tester
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12} className={classes.filterWrapper}>
                <TesterFilter onChange={handleUpdateFilters} classes={classes} />
            </Grid>

            <Grid item container xs={12}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="testers-title testers-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell
                                        sortDirection={sortBy === 'fullName' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'fullName'}
                                            direction={sortBy === 'fullName' ? sortOrder : 'asc'}
                                            onClick={handleSort('fullName')}
                                        >
                                            Name
                                            {sortBy === 'fullName' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'dob' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'dob'}
                                            direction={sortBy === 'dob' ? sortOrder : 'asc'}
                                            onClick={handleSort('dob')}
                                        >
                                            Age
                                            {sortBy === 'dob' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'gender' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'gender'}
                                            direction={sortBy === 'gender' ? sortOrder : 'asc'}
                                            onClick={handleSort('gender')}
                                        >
                                            Gender
                                            {sortBy === 'gender' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'disability' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'disability'}
                                            direction={sortBy === 'disability' ? sortOrder : 'asc'}
                                            onClick={handleSort('disability')}
                                        >
                                            Disability
                                            {sortBy === 'disability' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'status' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'status'}
                                            direction={sortBy === 'status' ? sortOrder : 'asc'}
                                            onClick={handleSort('status')}
                                        >
                                            Status
                                            {sortBy === 'status' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {testers.map((tester) => (
                                    <TableRow key={tester.id}>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1" className={classes.testerName}>
                                                {tester.fullName}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">{tester.age}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">{tester.gender}</Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1">
                                                {/* eslint-disable-next-line no-nested-ternary */}
                                                {tester.hasDisability === true
                                                    ? 'Yes'
                                                    : tester.hasDisability === false
                                                      ? 'No'
                                                      : ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Pill {...getStatusLabel(tester)} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {canManageTesters && (
                                                <div>
                                                    <TesterActionMenu
                                                        testerId={tester.id}
                                                        tester={tester}
                                                        onDelete={handleDelete}
                                                        status={tester.status}
                                                        role={userRole}
                                                    />
                                                </div>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {testersTotal > 10 && (
                        <TablePagination
                            className={classes.tableFooter}
                            component="div"
                            count={testersTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            classes={{
                                root: classes.paginationContainer,
                                toolbar: classes.paginationToolbar,
                                spacer: classes.paginationSpacer,
                            }}
                        />
                    )}
                </Paper>
            </Grid>
            <NotificationSnackbar
                open={!!testerSuccessMessage}
                text={testerSuccessMessage}
                onClose={() => dispatch(clearUserSuccessMessage())}
            />
            <DeleteTesterModal
                tester={testerToDelete}
                onClose={() => setOpenDeleteModal(false)}
                open={openDeleteModal}
            />
        </Grid>
    );
};

ManageTesters.propTypes = {
    classes: PropTypes.object.isRequired,
    role: PropTypes.oneOf(Object.values(USER_ROLES).map((role) => role.value)).isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(ManageTesters, combinedStyles);
