import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ActionMenu from '../../../../components/action-menu';
import { USER_STATUS, inviteUser, updateInternalUser } from '../../../../redux/user';

const UserActionMenu = ({ userId, status }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInviteUser = useCallback(() => {
        dispatch(inviteUser({ id: userId }));
    }, [dispatch, userId]);

    const handleEditUser = useCallback(() => {
        navigate(`/dashboard/user/${userId}`);
    }, [navigate, userId]);

    const handleActivateUser = useCallback(() => {
        dispatch(updateInternalUser({ userId, data: { status: USER_STATUS.ACTIVE } }));
    }, [dispatch, userId]);

    const handleDeactivateUser = useCallback(() => {
        dispatch(updateInternalUser({ userId, data: { status: USER_STATUS.INACTIVE } }));
    }, [dispatch, userId]);

    const actions = [{ label: 'Edit', onClick: handleEditUser }];

    if (status === USER_STATUS.INVITED) {
        actions.push({ label: 'Resend Invitation', onClick: handleInviteUser });
    }

    if (status === USER_STATUS.INACTIVE) {
        actions.push({ label: 'Make Active', onClick: handleActivateUser });
    } else {
        actions.push({ label: 'Make Inactive', onClick: handleDeactivateUser });
    }

    const defaultAction =
        status === USER_STATUS.INVITED
            ? {
                  label: 'Resend Invitation',
                  onClick: handleInviteUser,
              }
            : {
                  label: 'Edit',
                  onClick: handleEditUser,
              };

    return <ActionMenu id={`${userId}-actions`} defaultAction={defaultAction} actions={actions} />;
};

UserActionMenu.propTypes = {
    userId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
};

export default UserActionMenu;
