import { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { isValid, format } from 'date-fns';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

import { saveReportSectionData, uploadReportAttachments, patchReportVersionSuccess, patchReportVersion, patchReportError, updateSectionProgress, REQUEST_STATUS } from '../../../redux/reports'; // flattenQuestions
import { getSectionByIdSelector } from '../../../redux/current-report/selectors';
import { getGetReportAttachmentsUploadStatus } from '../../../redux/reports/selectors';
import getValidation from '../../../validation/validation-schema-report';

import ReportSectionHeader from '../../../routes/report/components/section/header';
import FormButtons from '../../../routes/report/components/section/form-buttons';
import ReportSection from '../../../routes/report/components/section';
import AbandonChangesModal from '../../../components/abandon-changes-modal';

import ReportHeader from '../components/header';
import ReportError from '../components/report-error';
import { getRole } from '../../../redux/auth/selectors';
import Styles from './styles';
import { APIError } from '../../../redux/app';
import { SECTION_PROGRESS } from '../../../redux/current-report/constants';
import { getFormattedDate } from '../../../utils/get-formatted-date';
import { USER_ROLES } from '../../../utils/constants';
// import { current } from '@reduxjs/toolkit';

const debounce = (func, wait, immediate) => {
    let timeout;

    return (...args) => {
    const context = this;

      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
};

const useYupValidationResolver = (validationSchema) => async (data) => {
    if (!validationSchema || !Object.keys(validationSchema).length) {
        return {
        values: data,
        errors: {},
        };
    }

    try {
        const values = await validationSchema.validate(data, {
        abortEarly: false,
        });

        return {
            values,
            errors: {},
        };
    } catch (errors) {
        return {
        values: {},
        errors: errors?.inner?.reduce(
            (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
            },
            }),
            {},
        ),
        };
    }
    };

const ReportSectionLayout = ({ classes, currentReport, flaggedVersion }) => {
    // const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id: reportId } = currentReport;
    const { siteName } = currentReport.testSite;
    const { sectionId } = useParams();
    const getSectionById = useSelector(getSectionByIdSelector);
    const currentSection = getSectionById(sectionId);
    const userRole = useSelector(getRole);
    const reportAttachmentsUploadStatus = useSelector(getGetReportAttachmentsUploadStatus);
    const [uploadedAttachments, setUploadedAttachments] = useState([null]);

    const defaultValues = {};
    // look to the answers object which has the correct structure
    // NOTE: that this will bring in ALL answers for the report, not just the ones for this section
    // when we validate the form, we will only validate the questions for this section
    Object.entries(currentReport.reportAnswers).forEach((question) => {
        // question[0] = key
        // question[1] = value
        defaultValues[question[0]] = question[1] || null;
    });

    const [validationSchema, setValidationSchema] = useState({});
    const [formData, setFormData] = useState({});
    const getSchema = (data) => {
        setValidationSchema(getValidation(currentSection.questions, data));
    };

    const resolver = useMemo(() => useYupValidationResolver(validationSchema), [validationSchema]);
    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setValue,
        getValues,
        watch,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues,
        resolver,
    });
    const watchValues = watch();

    const updateFormValues = useCallback(debounce((data) => {
            getSchema(data);
        }, 500),
        [],
    );

    useEffect(() => {
        if (!isEqual(watchValues, formData)) {
            setFormData(watchValues);
            updateFormValues(watchValues);
        }
    }, [watchValues]);

    const handleDateChange = useCallback(
        (date, name) => {
            if (isValid(date)) {
                setValue(name, getFormattedDate(date), { shouldValidate: true, shouldDirty: true });
            } else if (date === null) setValue(name, null, { shouldValidate: true, shouldDirty: true });
        },
        [setValue],
    );

    const handleTimeChange = useCallback(
        (time, name) => {
            setValue(name, format(time, 'hh:mm aa'), { shouldValidate: true, shouldDirty: true });
        },
        [setValue],
    );

    const onError = (error) => {
        // TODO: validate error object structure
        if (error.status === 400) {
            dispatch(APIError({ text: 'Error Saving reports' }));
        } else if (error.status === 422) {
            dispatch(APIError({ text: 'Error: you appear to be offline, dont navigate away or you will lose your entered data. Try saving once you have connectivity.' }));
        }
    };

    const onComplete = () => {
        navigate('/report');
    };

    const saveAttachments = async () => {
        const validAttachments = uploadedAttachments.filter((attachment) => attachment !== null);

        if (validAttachments.length !== 0) {
            await dispatch(
                uploadReportAttachments({
                    reportId,
                    attachments: validAttachments,
                    questions: currentSection.questions,
                    onError,
                }),
            );
        }
    };

    const onFormSubmit = async () => {
        await saveAttachments();

        // we use watchValues, because it will carry all data transformations (serializers, etc.)
        // because we have a custom resolver, it seemed the date transformations were not being carried forward on submit
        const answers = watchValues;
        const { questions } = currentSection;

        dispatch(
            saveReportSectionData({
                status: 'completed',
                reportId,
                sectionId,
                questions,
                answers,
                onComplete,
                onError,
            }),
        );

        // TODO: this marks the section as complete
        // it's unclear if we need some validation for the flagged answers to ensure they've been updated first
        dispatch(updateSectionProgress(currentReport.id, currentSection.id, SECTION_PROGRESS.COMPLETED));
    };

    const onSaveDraft = async () => {
        await saveAttachments();

        const { questions } = currentSection;

        dispatch(
            saveReportSectionData({
                status: 'draft',
                reportId,
                sectionId,
                questions,
                answers: getValues(),
                onComplete,
                onError,
            }),
        );
    };

    const [abandonFormModalOpen, setAbandonFormModalOpen] = useState(false);
    const onCancelFeedback = () => {
        setAbandonFormModalOpen(true);
    };
    const handleConfirmCancelFeedback = () => {
        // feedback is only stored in the redux store, so if we navigate back it will reset
        navigate('/report');
    };

    const onSaveFeedback = () => {
        // add error messages if any flaggedAnswers are missing a reason
        const flaggedAnswers = flaggedVersion.flaggedAnswers || {};
        let hasErrors = false;
        const validatedAnswers = Object.entries(flaggedAnswers).reduce((acc, [questionId, data]) => {
            if (!data.reason) {
                hasErrors = true;
                acc[questionId] = { ...data, error: 'Reason is required' };
            } else {
                const { error, ...validatedData } = data;
                acc[questionId] = validatedData;
            }
            return acc;
        }, {});
        const { id, ...payload } = flaggedVersion;
        if (hasErrors) {
            dispatch(patchReportVersionSuccess(reportId, id, { ...flaggedVersion, flaggedAnswers: validatedAnswers }));
        } else {
            const onSuccess = () => {
                dispatch(patchReportVersionSuccess(reportId, id, { ...flaggedVersion, flaggedAnswers: validatedAnswers }));

                let sectionContainsFlaggedAnswers = false;
                Object.values(currentSection.questions).forEach((question) => {
                    if (validatedAnswers[question.id]) {
                        sectionContainsFlaggedAnswers = true;
                    }
                });
                if (sectionContainsFlaggedAnswers) {
                    // if flagged answers are present, set the section to flagged
                    dispatch(updateSectionProgress(currentReport.id, currentSection.id, SECTION_PROGRESS.FLAGGED));
                } else {
                    // if no flagged answers are present, set the section to completed
                    dispatch(updateSectionProgress(currentReport.id, currentSection.id, SECTION_PROGRESS.COMPLETED));
                }

                onComplete();
            };
            const onErrorPatch = (error) => {
                if (error?.response?.status === 401) {
                    dispatch(patchReportError(reportId, { message: 'Unable to save your changes. Your session may have expired, if the problem persists please log out and back in again.' }, REQUEST_STATUS.SESSION_EXPIRED));
                } else {
                    dispatch(patchReportError(reportId, { message: 'Unable to save your changes.' }, REQUEST_STATUS.ERROR));
                }
            };
            dispatch(patchReportVersion({ versionId: id, payload: { ...payload, flaggedAnswers: validatedAnswers, sectionId }, onSuccess, onError: onErrorPatch }));
        }
    };

    const onBackButtonClick = () => {
        // if the user is a tester, we want to save the draft when they navigate back
        if (userRole === USER_ROLES.tester.value) {
            onSaveDraft();
        } else {
            // if the user is not a tester, we assume they are a reviewing admin, we want to cancel the feedback
            onCancelFeedback();
        }
    };

    const sectionProps = {
        id: currentReport.id,
        instructions: currentSection.instructions,
        questions: currentSection.questions,
        onSaveDraft,
        control,
        register,
        watchValues,
        errors,
        flaggedAnswers: flaggedVersion.flaggedAnswers || {},
        handleDateChange,
        handleTimeChange,
        setUploadedAttachments,
        setValue,
    };

    const [progressBlockerOpen, setProgressBlockerOpen] = useState(false);
    const handleCloseProgressBlocker = () => {
        setProgressBlockerOpen(false);
    };

    useEffect(() => {
        if (reportAttachmentsUploadStatus?.inProgress) {
            setProgressBlockerOpen(true);
        } else if (!reportAttachmentsUploadStatus?.inProgress) {
            handleCloseProgressBlocker();
        }
    }, [reportAttachmentsUploadStatus]);

    return (
        <>
            <ReportHeader backButtonLabel={`Report: ${siteName}`} onBackButtonClick={onBackButtonClick} />
            <main>
                <AbandonChangesModal
                    open={abandonFormModalOpen}
                    handleClose={() => setAbandonFormModalOpen(false)}
                    handleConfirm={handleConfirmCancelFeedback}
                />
                <Container maxWidth="lg">
                    <Grid item container spacing={2} className={classes.container}>
                        <Grid item xs={4}>
                            <ReportSectionHeader section={currentSection} />
                        </Grid>
                        <Grid item xs={8}>
                            <form noValidate>
                                <Backdrop
                                    className={classes.backdrop}
                                    open={progressBlockerOpen}
                                    onClick={handleCloseProgressBlocker}
                                >
                                    <CircularProgress color="inherit" style={{ marginBottom: 30 }} />
                                    <Typography variant="h3" style={{ color: 'white' }}>
                                        Uploading attachments...
                                    </Typography>
                                </Backdrop>

                                <ReportSection {...sectionProps} />

                                <ReportError currentReport={currentReport} />

                                <FormButtons
                                    onSaveDraft={onSaveDraft}
                                    onSubmit={handleSubmit(onFormSubmit)}
                                    onSaveFeedback={onSaveFeedback}
                                    onCancelFeedback={onCancelFeedback}
                                    role={userRole}
                                />
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </main>
        </>
    );
};

ReportSectionLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    currentReport: PropTypes.object.isRequired,
    flaggedVersion: PropTypes.object.isRequired,
};

export default withStyles(ReportSectionLayout, Styles);
