import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import { ErrorMessage } from '@hookform/error-message';
import FormHelperText from '@mui/material/FormHelperText';

import RenderLogicCondition from './render-logic-condition';
import RenderLogicValue from './render-logic-value';
import Colors from '../../../../../styles/colors';

const QuotedAnswerText = styled(Typography)({
    fontStyle: 'italic',
    color: '#5C6E81',
    marginTop: 10,
});

const ConditionalQuestionLogic = ({ parentQuestion, selectedCondition, fieldName, control, errors, getValues, setValue, register, watchValues }) => {
    // NOTE: we manage errors for the value and condition fields in this component
    // we do not pass the errors down to the child components
    if (parentQuestion.type !== 'repeatingGroup' && parentQuestion.type !== 'instructions') {
        return (
            <Grid item container xs={11} style={{ borderRadius: 10, border: `1px solid ${Colors.lavenderGray}`, backgroundColor: Colors.antiflashWhite, width: '100%', padding: 20 }}>
                <Grid item xs={12}>
                    <Typography variant="body1">Show the next question if the following condition matches from the previous question:</Typography>
                    <QuotedAnswerText variant="body1">&quot;{parentQuestion.text}&quot;</QuotedAnswerText>
                </Grid>
                <Grid item container xs={12} spacing={3} style={{ alignItems: 'center' }}>
                    <Grid item xs="auto">
                        Answer
                    </Grid>
                    <Grid item xs>
                        <RenderLogicCondition
                            control={control}
                            errors={{}}
                            getValues={getValues}
                            setValue={setValue}
                            parentType={parentQuestion.type}
                            fieldName={`${fieldName}.condition`}
                        />
                    </Grid>
                    <Grid item xs>
                        {/* we only show the value field if the condition has been selected */}
                        {selectedCondition && (
                            <RenderLogicValue
                                parent={parentQuestion}
                                selectedCondition={selectedCondition}
                                fieldName={`${fieldName}.value`}
                                control={control}
                                errors={{}}
                                register={register}
                                watchValues={watchValues}
                                setValue={setValue}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                        <FormHelperText id={`${fieldName}.value-error`} role="alert" aria-live="polite" color="error" error>
                            <ErrorMessage name={`${fieldName}.value`} errors={errors} />
                        </FormHelperText>
                        <FormHelperText id={`${fieldName}.condition-error`} role="alert" aria-live="polite" color="error" error>
                            <ErrorMessage name={`${fieldName}.condition`} errors={errors} />
                        </FormHelperText>
                </Grid>
            </Grid>
        );
    }
    return null;
};

ConditionalQuestionLogic.defaultProps = {
    selectedCondition: undefined,
};

ConditionalQuestionLogic.propTypes = {
    fieldName: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    getValues: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    parentQuestion: PropTypes.object.isRequired,
    selectedCondition: PropTypes.string,
    register: PropTypes.func.isRequired,
    watchValues: PropTypes.object.isRequired,
};

export default ConditionalQuestionLogic;
