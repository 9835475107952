import { createSelector } from '@reduxjs/toolkit';

import { ICON_MAP, SECTION_PROGRESS } from './constants';

import { getReports } from '../reports/selectors';
import {
    determineSectionsProgress,
    getFlaggedVersion,
} from '../reports';

const getCurrentReportState = (state) => state.currentReport;

export const getCurrentReportId = createSelector(getCurrentReportState, (currentReport) => currentReport.id);

export const getCurrentReport = createSelector(getCurrentReportId, getReports, (reportId, reports) => {
    if (!reportId) return null;

    /*
        - sectionsProgress is an object that tracks the progress of each section
        - this is used to determine the UI presentation on each ReportBlockButton
        - also used to determine if the report is completed, which would allow it to be subitted
        - sectionProgress was previously only calulated during "parseFetchedReports", which is triggered on the My Reports dashboard
        - calculating it here allows the progress to be updated in real-time as the user interacts with the report
    */
    const flaggedAnswers = getFlaggedVersion(reports[reportId])?.flaggedAnswers;
    return {
        id: reportId,
        ...reports[reportId],
        sectionsProgress: determineSectionsProgress(reports[reportId].reportAnswers, flaggedAnswers, reports[reportId].test.sections),
    };
});

export const getCurrentReportProgress = createSelector(getCurrentReport, (currentReport) => {
    // if this section doesn't exist on the report, it's not started
    if (!currentReport.sectionsProgress) return SECTION_PROGRESS.NOT_STARTED;

    // create an object to track the progress of each section
    const progress = {
        [SECTION_PROGRESS.NOT_STARTED]: 0,
        [SECTION_PROGRESS.IN_PROGRESS]: 0,
        [SECTION_PROGRESS.COMPLETED]: 0,
    };
    // create an array with the progress value of each section
    // not all sections will be accounted for in the progress object
    const sections = Object.values(currentReport.sectionsProgress);

    // build out progress object based on the values of the sections array
    sections.forEach((sectionProgress) => {
        progress[sectionProgress] += 1;
    });

    // if all sections are completed, return completed
    if (progress[SECTION_PROGRESS.COMPLETED] === currentReport.test.sections.length) return SECTION_PROGRESS.COMPLETED;
    if (progress[SECTION_PROGRESS.NOT_STARTED] === currentReport.test.sections.length) return SECTION_PROGRESS.NOT_STARTED;

    return SECTION_PROGRESS.IN_PROGRESS;
});

export const getSections = createSelector(getCurrentReport, (currentReport) =>
    currentReport.test.sections.map((section) => ({
        ...section,
        Icon: ICON_MAP[section.icon],
        questions: section.questions,
        progress: currentReport.sectionsProgress?.[section.id] || SECTION_PROGRESS.NOT_STARTED,
    })),
);

export const getSectionByIdSelector = createSelector(
    getSections,
    (sections) => (sectionId) => sections.find((section) => section.id === sectionId),
);
