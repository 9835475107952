import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { withStyles } from 'tss-react/mui';
import isEqual from 'lodash/isEqual';

import TablePaginationActions from '../../components/table-pagination-actions';
import { getReports, REPORT_STATUS } from '../../redux/reports';
import { getReportsRawData, getReportsTotal } from '../../redux/reports/selectors';
import { getTestersForReportsFilters } from '../../redux/testers';
import { getSitesForReportsFilters } from '../../redux/sites';
import getFormattedDate from '../../utils/get-formatted-date';

import ReportFilter from './components/report-filter';
import OutcomePill from './components/outcome-pill';
import ReportActionMenu from './components/report-action-menu';
import UploadOutcomeDialog from './components/upload-outcome-dialog';
import useReportDialog from './utils/use-report-dialog';

import CombineStyles from '../../utils/combine-styles';
import ButtonStyles from '../../styles/buttons';
import Styles from './styles';
import DeleteReportDialog from './components/delete-report-dialog';
import { getRole } from '../../redux/auth/selectors';
import { setCurrentReportId } from '../../redux/current-report';

const AdminDashboard = ({ classes }) => {
    const userRole = useSelector(getRole);
    const reports = useSelector(getReportsRawData);
    const totalReports = useSelector(getReportsTotal);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [reportToDelete, setReportToDelete] = useState({});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [testerFilterOptions, setTesterFilterOptions] = useState([]);
    const [sitesFilterOptions, setSitesFilterOptions] = useState([]);
    const [filterCriteria, setFilterCriteria] = useState({
        statuses: [],
        sites: [],
        testers: [],
    });
    const [sortBy, setSortBy] = useState('testDate');
    const [sortOrder, setSortOrder] = useState('asc');
    const [reportQueryParams, setReportQueryParams] = useState({
        pageNumber: page,
        pageSize: rowsPerPage,
        sortBy: 'testDate',
    });

    const { handleOpenDialog: handleOpenOutcomeDialog, dialogProps: uploadOutcomeDialogProps } = useReportDialog();

    const handleSendBackToTester = (reportId) => {
        dispatch(setCurrentReportId(reportId));
        navigate('/report');
    };

    const handleSort = (sortProperty) => () => {
        setSortBy(sortProperty);
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    useEffect(() => {
        const onTesterSuccess = (testers) => {
            setTesterFilterOptions(testers);
        };
        dispatch(getTestersForReportsFilters(onTesterSuccess));

        const onSiteSuccess = (sites) => {
            setSitesFilterOptions(sites);
        };
        dispatch(getSitesForReportsFilters(onSiteSuccess));
    }, [dispatch]);
    useEffect(() => {
        const params = {
            pageNumber: page,
            sortBy,
            sortOrder,
        };

        if (rowsPerPage > 0) {
            params.pageSize = rowsPerPage;
        }

        Object.entries(filterCriteria).forEach(([key, value]) => {
            if (value.length > 0) {
                params[key] = value;
            }
        });
        if (!isEqual(params, reportQueryParams)) {
            setReportQueryParams(params);
        }
    }, [filterCriteria, page, rowsPerPage, sortBy, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(getReports({ params: reportQueryParams }));
    }, [reportQueryParams, dispatch]);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalReports - page * rowsPerPage);

    const handleChangePage = useCallback(
        (event, newPage) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleChangeRowsPerPage = useCallback(
        (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        [setRowsPerPage, setPage],
    );

    const handleOpenDeleteReportDialog = (report) => {
        setReportToDelete(report);
        setOpenDeleteModal(true);
    };

    const getStatusOrOutcome = (report) => {
        if (report.status === REPORT_STATUS.SUBMITTED) {
            return report.outcome || REPORT_STATUS.SUBMITTED;
        }
        return report.status;
    };

    const handleUpdateFilters = useCallback(
        (values) => {
            if (!isEqual(values, filterCriteria)) {
                setPage(0);
                setFilterCriteria(values);
            }
        },
        [filterCriteria],
    );

    return (
        <Grid item container spacing={2} className={classes.dashboardContainer}>
            <Grid item sm={12}>
                <Typography id="report-title" variant="h1" component="h2" className={classes.pageTitle}>
                    All Reports
                </Typography>
                <Typography id="report-count" className={classes.pageCount} variant="body1">
                    {totalReports} reports
                </Typography>
            </Grid>

            <Grid item sm={12}>
                <ReportFilter
                    onChange={handleUpdateFilters}
                    testerFilterOptions={testerFilterOptions}
                    sitesFilterOptions={sitesFilterOptions}
                />
            </Grid>

            <Grid item sm={12}>
                <Paper className={classes.tableWrapper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-labelledby="report-title report-count">
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell
                                        sortDirection={sortBy === 'testDate' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'testDate'}
                                            direction={sortBy === 'testDate' ? sortOrder : 'asc'}
                                            onClick={handleSort('testDate')}
                                        >
                                            Test Date
                                            {sortBy === 'testDate' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'site' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'site'}
                                            direction={sortBy === 'site' ? sortOrder : 'asc'}
                                            onClick={handleSort('site')}
                                        >
                                            Site
                                            {sortBy === 'site' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        sortDirection={sortBy === 'tester' ? sortOrder : false}
                                        className={classes.tableCell}
                                    >
                                        <TableSortLabel
                                            active={sortBy === 'tester'}
                                            direction={sortBy === 'tester' ? sortOrder : 'asc'}
                                            onClick={handleSort('tester')}
                                        >
                                            Tester
                                            {sortBy === 'tester' ? (
                                                <span className="screen-reader-only">
                                                    {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableCell}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {reports.map((report) => (
                                    <TableRow key={report.id}>
                                        <TableCell className={`${classes.tableCell} ${classes.noWrapCell}`}>
                                            {getFormattedDate(report.testDate)}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Typography variant="body1" className={classes.siteName}>
                                                {report.testSite.siteName}
                                            </Typography>
                                            {report.testSite.owner && (
                                                <Typography variant="body1" className={classes.siteOwner}>
                                                    Owner: {report.testSite.owner}
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{report.tester.fullName}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <OutcomePill outcome={getStatusOrOutcome(report)} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 50 }}>
                                            <ReportActionMenu
                                                reportId={report.id}
                                                report={report}
                                                handleActions={{
                                                    handleOpenOutcomeDialog,
                                                    handleSendBackToTester,
                                                    handleOpenDeleteReportDialog,
                                                }}
                                                role={userRole}
                                            />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell className={classes.emptyTableCell} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {totalReports > 10 && (
                        <TablePagination
                            className={classes.tableFooter}
                            component="div"
                            count={totalReports}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            classes={{
                                root: classes.paginationContainer,
                                toolbar: classes.paginationToolbar,
                                spacer: classes.paginationSpacer,
                            }}
                        />
                    )}
                </Paper>
            </Grid>

            {totalReports > 0 && (
                <>
                    <UploadOutcomeDialog {...uploadOutcomeDialogProps} />
                    <DeleteReportDialog
                        isDialogOpen={openDeleteModal}
                        reportDetails={reportToDelete}
                        handleCloseDialog={() => setOpenDeleteModal(false)}
                    />
                </>
            )}
        </Grid>
    );
};

AdminDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const combinedStyles = CombineStyles(ButtonStyles, Styles);
export default withStyles(AdminDashboard, combinedStyles);
