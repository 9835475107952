import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';

import { createTester, updateTester } from '../../../redux/testers';
import validationSchema from '../../../validation/validation-schema-tester';
import TextInput from '../../../components/inputs/textinput';
import Select from '../../../components/inputs/select';
import RadioGroup from '../../../components/inputs/radiogroup';
import RadioButton from '../../../components/inputs/radiobutton';
import TextArea from '../../../components/inputs/textarea';
import ConditionalQuestions from '../../../components/inputs/conditional-questions';
import NotificationSnackbar from '../../../components/notification-snackbar';
import TextMaskDate from '../../../components/inputs/text-mask-date';
import useSnackbar from '../../../utils/use-snackbar';

import states from './states';

import CombineStyles from '../../../utils/combine-styles';
import ButtonStyles from '../../../styles/buttons';
import InputStyles from '../../../styles/inputs';
import Styles from './styles';

const RACES = [
    'White',
    'Black or African American',
    'Hispanic',
    'American Indian or Alaska Native',
    'Asian',
    'Native Hawaiian or Other Pacific Islander',
    'Other',
];
const GENDERS = ['Female', 'Male', 'Non-binary', 'Trans'];
const initialValues = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    address: null,
    city: null,
    state: '',
    zip: null,
    race: '',
    raceOther: null,
    gender: null,
    hasDisability: null,
    disability: '',
    dob: null,
    comments: '',
};

const InviteEditTesterForm = ({ classes, testerId, tester }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleOpenSnackbar, snackbarProps } = useSnackbar();

    const isEditing = testerId !== null && tester !== null;

    const { control, register, watch, handleSubmit, reset, formState } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: !isEditing
            ? initialValues
            : {
                  ...tester,
                  // convert boolean to string for better handling in RadioGroup
                  hasDisability: tester.hasDisability ? 'true' : 'false',
                  race: RACES.includes(tester.race) ? tester.race : 'Other',
                  raceOther: !RACES.includes(tester.race) ? tester.race : null,
                  disability: tester.disability ? tester.disability : '',
                  comments: tester.comments ? tester.comments : '',
              },
    });
    const { errors } = formState;

    const raceValue = watch('race');
    const hasDisabilityValue = watch('hasDisability');

    const onSubmitNew = useCallback(
        (values) => {
            const onSuccess = () => {
                reset(initialValues, { submitCount: true });
                handleOpenSnackbar({ text: 'Your invitation has been sent!', type: 'success' });
            };
            const onError = (error) => {
                let text = 'There was a problem inviting the tester.  Please try again later.';

                // 409 Conflict indicates that this is a tester that has already been added
                if (error.response.status === 409) {
                    text = 'This tester has already been invited.';
                }

                handleOpenSnackbar({ text, type: 'error' });
            };

            const testerData = { ...values };

            if (values.raceOther) {
                testerData.race = values.raceOther;
            }

            delete testerData.raceOther;

            dispatch(createTester({ tester: testerData, onSuccess, onError }));
        },
        [dispatch, reset, handleOpenSnackbar],
    );

    const onSubmitEdit = useCallback(
        (values) => {
            const onSuccess = () => {
                navigate('/dashboard/testers');
            };

            const onError = () => {
                handleOpenSnackbar({
                    text: 'There was a problem editing the tester.  Please try again later.',
                    type: 'error',
                });
            };

            const testerData = { ...values };

            if (values.raceOther) {
                testerData.race = values.raceOther;
            }

            delete testerData.raceOther;

            dispatch(updateTester({ testerId, testerData, onSuccess, onError }));
        },
        [dispatch, navigate, testerId, handleOpenSnackbar],
    );

    return (
        <>
            <form onSubmit={handleSubmit(isEditing ? onSubmitEdit : onSubmitNew)} noValidate>
                <Grid item container spacing={2}>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextInput
                                name="firstName"
                                label="First Name"
                                errors={errors}
                                required
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput
                                name="lastName"
                                label="Last Name"
                                errors={errors}
                                required
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.inputWrapper}>
                        <TextInput
                            name="email"
                            label="Email"
                            errors={errors}
                            required
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.inputWrapper}>
                        <TextInput
                            name="phone"
                            label="Phone Number"
                            errors={errors}
                            required
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.inputWrapper}>
                        <TextInput
                            name="address"
                            label="Address"
                            errors={errors}
                            required
                            register={register}
                            inputProps={{
                                autoComplete: 'off',
                            }}
                        />
                    </Grid>

                    <Grid item container xs={12} spacing={2}>
                        <Grid item xs={5} className={classes.inputWrapper}>
                            <TextInput
                                name="city"
                                label="City"
                                errors={errors}
                                required
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>
                        <Grid item xs className={classes.inputWrapper}>
                            <Select
                                placeholder=" - "
                                name="state"
                                label="State"
                                errors={errors}
                                required
                                control={control}
                                displayEmpty
                            >
                                <MenuItem disabled value="">
                                    {' '}
                                    -{' '}
                                </MenuItem>
                                {states.map((state) => (
                                    <MenuItem key={state} value={state}>
                                        {state}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={4} className={classes.inputWrapper}>
                            <TextInput
                                name="zip"
                                label="Zip Code"
                                errors={errors}
                                required
                                register={register}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.inputWrapper}>
                        <Select
                            placeholder=""
                            name="race"
                            label="Race/Ethnicity"
                            errors={errors}
                            required
                            control={control}
                            displayEmpty
                            style={{ width: '100%' }}
                        >
                            <MenuItem disabled value="">
                                Select
                            </MenuItem>
                            {RACES.map((race) => (
                                <MenuItem key={race} value={race}>
                                    {race}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <ConditionalQuestions condition={raceValue === 'Other'}>
                            <div className={classes.inputWrapper}>
                                <TextInput
                                    name="raceOther"
                                    label="If other, please provide race/ethnicity"
                                    errors={errors}
                                    required
                                    register={register}
                                    inputProps={{
                                        autoComplete: 'off',
                                    }}
                                />
                            </div>
                        </ConditionalQuestions>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.inputWrapper}>
                            <RadioGroup
                                name="gender"
                                label="Gender Identity"
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                {GENDERS.map((gender) => (
                                    <RadioButton key={gender} value={gender} label={gender} buttonStyle />
                                ))}
                            </RadioGroup>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className={classes.inputWrapper}>
                            <RadioGroup
                                name="hasDisability"
                                label="Disability Status"
                                errors={errors}
                                required
                                row
                                control={control}
                            >
                                <RadioButton value="true" label="Yes" buttonStyle />
                                <RadioButton value="false" label="No" buttonStyle />
                            </RadioGroup>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <ConditionalQuestions condition={hasDisabilityValue === 'true'}>
                            <div className={classes.inputWrapper}>
                                <TextArea
                                    name="disability"
                                    label="If yes, please describe"
                                    errors={errors}
                                    required
                                    register={register}
                                    rowsMin={3}
                                />
                            </div>
                        </ConditionalQuestions>
                    </Grid>

                    <Grid item xs={12} className={classes.inputWrapper}>
                        <TextMaskDate
                            name="dob"
                            label="Date of Birth"
                            control={control}
                            placeholder="ex. 01/01/2000"
                            errors={errors}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} className={classes.inputWrapper}>
                        <TextArea name="comments" label="Comments" errors={errors} register={register} rowsMin={3} />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            className={`${classes.primaryButton}`}
                            classes={{
                                label: classes.buttonLabel,
                            }}
                            TouchRippleProps={{
                                classes: {
                                    childPulsate: classes.primaryButtonRippleChildPulsate,
                                    ripplePulsate: classes.buttonRipplePulsate,
                                },
                            }}
                            aria-label={isEditing ? 'Save updates to tester' : null}
                            type="submit"
                        >
                            {isEditing ? 'Update' : 'Send Invitation'}
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <NotificationSnackbar {...snackbarProps} />
        </>
    );
};

InviteEditTesterForm.defaultProps = {
    testerId: null,
    tester: null,
};

InviteEditTesterForm.propTypes = {
    classes: PropTypes.object.isRequired,
    testerId: PropTypes.string,
    tester: PropTypes.object,
};

const combinedStyles = CombineStyles(ButtonStyles, InputStyles, Styles);
export default withStyles(InviteEditTesterForm, combinedStyles);
